import { createSelector } from '@reduxjs/toolkit';

const transformData = (data) => ({
    header: data.header,
    description: data.description,
    img: {
        desktop: {
            src: data.sideImageDesktop,
            alt: data.sideImageDesktopAlt,
        },
        mobile: {
            src: data.sideImageMobile,
            alt: data.sideImageMobileAlt,
        },
    },
    formText: data.formText,
    thankYouMessage: data.thankYouMessage,
});

export const jamenetReviewSelector = createSelector(
    (state) => state.main,
    (mainState) => {
        return {
            title: mainState && mainState.title,
            buttonInfo: mainState && mainState.homePayload,
            formState: mainState && mainState.jamenetReviewFormState,
            jmnetReviewIntro:
                mainState &&
                mainState.jamenetReviewPayload &&
                transformData(mainState.jamenetReviewPayload),
            magazine: {
                title: 'Jamenet Review 01',
                date: 'Septiembre 2022',
                img: '/revista/jmnt-review-2.jpg',
                alt: 'portada revista',
                url: 'http://api.jamenet.eus/jamenet_review/Jamenet_Review_2022.pdf',
            },
            ...mainState,
        };
    }
);

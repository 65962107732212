import React from 'react';
import './gmh.css';

export const gmhView = () => {
    return (
        <div className="container-gmh">
            <div className="hero">
                <img
                    alt=""
                    className="hero__bg-img"
                    src="http://pro.gmh.eus/img/home/GMH-dktp/Img_slider.png"
                />
                <div className="hero__text-container">
                    <h3>NUEVA</h3>
                    <h2>Colección 2022</h2>
                    <div className="hero__text-btn">
                        <a href="http://pro.gmh.eus/es/3-hosteleria-y-restauracion">
                            Ver más
                        </a>
                    </div>
                </div>
            </div>
            <div className="center-container">
                <div className="subbanners-container">
                    <div className="subbanner-container">
                        <img
                            alt=""
                            className="subbanner-img"
                            src="http://pro.gmh.eus/img/home/GMH-dktp/button-hosteleria.png"
                        />
                        <div className="subbanner-text-container first-subbanner-text-container">
                            <h2>Hostelería</h2>
                            <p>
                                <a href="http://pro.gmh.eus/es/3-hosteleria-y-restauracion">
                                    Ver más
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className="subbanner-container second-subbanner-container">
                        <img
                            alt=""
                            className="subbanner-img"
                            src="http://pro.gmh.eus/img/home/GMH-dktp/button-escolares.png"
                        />
                        <div className="subbanner-text-container second-subbanner-text-container">
                            <h2>Uniformes Escolares</h2>
                            <p>
                                <a href="http://pro.gmh.eus/es/4-uniformes-escolares">
                                    Ver más
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className="subbanner-container third-subbanner-container">
                        <img
                            alt=""
                            className="subbanner-img"
                            src="http://pro.gmh.eus/img/home/GMH-dktp/button-sanidad.png"
                        />
                        <div className="subbanner-text-container third-subbanner-text-container">
                            <h3>Sanidad</h3>
                            <p>
                                <a href="http://pro.gmh.eus/es/5-sanidad">
                                    Ver más
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="social-media-container">
                    <div className="backgrounds-container">
                        <div className="first-rectangle"></div>
                        <div className="second-rectangle"></div>
                    </div>
                    <div className="content-container">
                        <div className="social-media-img-container">
                            <img
                                alt=""
                                className="social-media-img"
                                src="http://pro.gmh.eus/img/home/GMH-dktp/Slider_promo_gmh.png"
                            />
                        </div>
                        <div className="social-media-text-container">
                            <h4>@gmhuniformes</h4>
                            <h2>Síguenos en nuestras redes sociales</h2>
                            <div className="social-media-icons">
                                <img
                                    src="http://pro.gmh.eus/img/twitter.svg"
                                    alt=""
                                />
                                <img
                                    src="http://pro.gmh.eus/img/facebook.svg"
                                    alt=""
                                />
                                <img
                                    src="http://pro.gmh.eus/img/instagram.svg"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="info-bar-container">
                <div className="info__bar-section">
                    <img
                        alt=""
                        className="info-bar__img"
                        src="http://pro.gmh.eus/img/desktop_gmh_6.png"
                    />
                    <div className="info__bar-section--content">
                        <h5>Envío gratuito</h5>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur a elit. In
                            ut ullamcorper leo, eget
                        </p>
                    </div>
                </div>
                <div className="info__bar-section">
                    <img
                        alt=""
                        className="info-bar__img"
                        src="http://pro.gmh.eus/img/desktop_gmh_7.png"
                    />
                    <div className="info__bar-section--content">
                        <h5>Pago seguro</h5>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur a elit. In
                            ut ullamcorper leo, eget
                        </p>
                    </div>
                </div>
                <div className="info__bar-section">
                    <img
                        alt=""
                        className="info-bar__img"
                        src="http://pro.gmh.eus/img/desktop_gmh_8.png"
                    />
                    <div className="info__bar-section--content">
                        <h5>Encuéntranos</h5>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur a elit. In
                            ut ullamcorper leo, eget
                        </p>
                    </div>
                </div>
            </div>

            <div className="double-banner">
                <div className="double-banner__left-banner">
                    <img
                        alt=""
                        className="double-banner__banner-bg mobile"
                        src="http://pro.gmh.eus/img/home/GMH-mobile/Banner-1.png"
                    />
                    <img
                        alt=""
                        className="double-banner__banner-bg desktop"
                        src="http://pro.gmh.eus/img/home/GMH-dktp/Banner-1.png"
                    />
                    <div className="double-banner__left-content">
                        <h3>Utiliza calzado cómodo y seguro</h3>
                        <h5>
                            Lorem ipsum dolor sit amet, consectetur a elit. In
                            ut ullamcorper leo, eget
                        </h5>
                    </div>
                </div>
                <div className="double-banner__right-banner">
                    <img
                        alt=""
                        className="double-banner__banner-bg mobile"
                        src="http://pro.gmh.eus/img/home/GMH-mobile/Banner-2.png"
                    />
                    <img
                        alt=""
                        className="double-banner__banner-bg desktop"
                        src="http://pro.gmh.eus/img/home/GMH-dktp/Banner-2.png"
                    />
                    <div className="double-banner__right-content">
                        <h2>50%</h2>
                        <h3>de descuento en ropa escolar</h3>
                    </div>
                </div>
                <div className="brand-banner">
                    <div className="brand-banner__brand">
                        <img
                            className="desktop"
                            src="http://pro.gmh.eus/img/home/GMH-dktp/1-Manufacturers-ct.png"
                            alt=""
                        />
                        <img
                            className="mobile"
                            src="http://pro.gmh.eus/img/home/GMH-mobile/1-Manufacturers-ct.png"
                            alt=""
                        />
                    </div>
                    <div className="brand-banner__brand">
                        <img
                            className="desktop"
                            src="http://pro.gmh.eus/img/home/GMH-dktp/2-Manufacturers-ct.png"
                            alt=""
                        />
                        <img
                            className="mobile"
                            src="http://pro.gmh.eus/img/home/GMH-mobile/2-Manufacturers-ct.png"
                            alt=""
                        />
                    </div>
                    <div className="brand-banner__brand">
                        <img
                            className="desktop"
                            src="http://pro.gmh.eus/img/home/GMH-dktp/LogoGMH.png"
                            alt=""
                        />
                        <img
                            className="mobile"
                            src="http://pro.gmh.eus/img/home/GMH-mobile/LogoGMH.png"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export const t = {
    submit: {
        es: 'Enviar',
        eu: 'Bidali',
    },
    name: {
        es: 'Nombre',
        eu: 'Izena',
    },
    surname: {
        es: 'Apellido',
        eu: 'Abizena',
    },
    email: {
        es: 'Email',
        eu: 'Posta elektronikoa',
    },
    download: {
        es: 'Descargar',
        eu: 'Deskargatu',
    },
    accept_provicy: {
        es: 'He leído y acepto la <a href="/es/privacidad" target="_blank" rel="noopener noreferrer">política de privacidad</a> y la <a href="/es/cookies" target="_blank" rel="noopener noreferrer">política de cookies</a>',
        eu: '<a href="/eu/pribatasuna" target="_blank" rel="noopener noreferrer">Pribatasun politika</a> eta  <a href="/eu/cookies" target="_blank" rel="noopener noreferrer">cookien politika </a>irakurri ditut eta onartzen ditut',
    },
    newsletter_suscribe: {
        es: 'Suscríbete a nuestra newsletter para estar al día',
        eu: 'Harpidetu gure newsletterrera egunean egoteko',
    },
    magazine_alt: {
        es: 'Portada de revista',
        eu: 'Aldizkariaren azala',
    },
    privacy_policy: {
        es: 'Politica de privacidad',
        eu: 'Pribatasun politika',
    },
    cookies: {
        es: 'Cookies',
        eu: 'Cookies',
    },
    generic_form_error: {
        es: 'Lo sentimos, ha ocurrido un error inesperado. Por favor, inténtelo de nuevo más tarde.',
        eu: 'Sentitzen dugu, ezusteko akatsa gertatu da. Mesedez, saiatu berriro geroago.',
    },
    generic_form_error_required: {
        es: 'Este campo es obligatorio.',
        eu: 'Eremu hau nahitaezkoa da.',
    },
    email_validation_error: {
        es: 'Introduzca un email válido.',
        eu: 'Sartu baliozko email bat.',
    },
    name_validation_error: {
        es: 'Introduzca un nombre válido.',
        eu: 'Sartu baliozko izen bat.',
    },
    surname_validation_error: {
        es: 'Introduzca un apellido válido.',
        eu: 'Sartu baliozko abizen bat.',
    },
    twitter_alt: {
        es: 'Logo de la red social Twitter',
        eu: 'Twitter sare sozialaren logoa',
    },
    linkedin_alt: {
        es: 'Logo de la red social LinkedIn',
        eu: 'LinkedIn sare sozialaren logoa',
    },
    instagram_alt: {
        es: 'Logo de la red social Instagram',
        eu: 'Instagram sare sozialaren logoa',
    },
    jamenet_alt: {
        es: 'Logo de de la empresa Jamenet',
        eu: 'Jamenet enpresaren logoa',
    },
    jamenet_address: {
        es: 'Nos puedes encontrar en Alameda Urquijo, 80, planta 1 (Bilbao)',
        eu: 'Urkixo zumarkalea 80, 1.solairua (Bilbo)',
    },
    jamenet_email: 'aupa@jamenet.eus',
    jamenet_copyright: '© Jamenet 2022',
    jamenet_phone: '944 01 30 74',
    back_home: 'Hasiera // Inicio',
};

import './Footer.css';
import { Link } from 'react-router-dom';
import { SocialButtonInstagram } from '../../../../atoms/socialButtonInstagram/SocialButtonInstagram';
import { SocialButtonLinkedin } from '../../../../atoms/socialButtonLinkedin/SocialButtonLinkedin';
import { SocialButtonTwitter } from '../../../../atoms/socialButtonTwitter/SocialButtonTwitter';
import { JamenetDots } from '../../../../atoms/jamenetDots/JamenetDots';
import { COOKIES, PRIVACY } from '../../../../../routing/routes';
import { t } from '../../../../../constants/transations';

export const FooterView = (props) => (
    <footer className="footer footer-bg">
        <div className="columns footer-div">
            <div className="column is-1" />
            <div className="column footer-box">
                <div id="a" className="footer-link">
                    {props &&
                        props.block1 &&
                        props.block1.map((item, key) =>
                            item && item.includes('Cookies') ? (
                                <Link
                                    key={key}
                                    to={COOKIES[props.lang]}
                                    className="is-footer-text has-text-left mt-6"
                                >
                                    {item}
                                </Link>
                            ) : (
                                <Link
                                    key={key}
                                    to={PRIVACY[props.lang]}
                                    className="is-footer-text has-text-left mt-2"
                                >
                                    {item}
                                </Link>
                            )
                        )}
                </div>
                <div id="b">
                    <div className="icons-position">
                        <SocialButtonInstagram
                            lang={props.lang}
                            white
                            className="footer-icons"
                        />
                        <SocialButtonLinkedin
                            lang={props.lang}
                            white
                            className="ml-4"
                        />
                        <SocialButtonTwitter
                            lang={props.lang}
                            white
                            className="ml-4"
                        />
                    </div>
                    {props &&
                        props.block2 &&
                        props.block2.map((item, key) => (
                            <div
                                key={key}
                                className="is-footer-text has-text-centered mt-2"
                                dangerouslySetInnerHTML={{ __html: item }}
                            />
                        ))}
                </div>
                <div id="c" className="footer-link">
                    {props &&
                        props.block3 &&
                        props.block3.map((item, key) => (
                            <p
                                key={key}
                                className="is-footer-text has-text-right mt-6"
                            >
                                {t.jamenet_copyright}
                            </p>
                        ))}
                    <JamenetDots className="footer-brand-img mt-2" />
                </div>
            </div>
            <div className="column is-1" />
        </div>
        <div><iframe src="https://pre-eleka.eleka.app" title="Eleka" height="700" width="370" style={{right: '0px', bottom: '0px', position: 'fixed'}}></iframe></div>
    </footer>
);
